import React from 'react';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import { path, prop } from 'ramda';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import theme from '@greenberry/salal/theme';
import Heading from '@greenberry/salal/components/Heading';
import HeroV1 from '@greenberry/salal/components/Hero/v1';
import Section from '@greenberry/salal/components/Section';
import HighlightCard from '@greenberry/salal/components/HighlightCard';
import Text from '@greenberry/salal/components/Text';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import MainWrapper from '@greenberry/salal/components/MainWrapper';
import CallToAction from '@greenberry/salal/components/CTA';
import mq from '@greenberry/salal/utils/mediaQuery';
import Accordion from '@greenberry/salal/components/Accordion';

import themes from '../constants/themes';
import parseTooltip from '../utils/parseTooltip';
import translateTheme from '../utils/translateTheme';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Search from '../components/Search';

const IntroductionHeading = styled(Heading)`
  text-align: center;
`;

const IntroductionText = styled(Heading)`
  display: block;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & > a:first-of-type {
    margin-bottom: ${theme.spacing('s')};
    ${mq('2')} {
      margin-bottom: ${theme.spacing('m')};
    }
  }
`;

const ButtonWrapper = styled.div`
  color: ${theme.color('primary.1')};
  font-weight: ${theme.get('type.fontWeight.bold')};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${theme.spacing('m')};
  ${mq('2')} {
    margin-bottom: ${theme.spacing('m')};
  }
`;

const StyledText = styled(Text)`
  ${mq('2')} {
    margin-bottom: ${theme.spacing('l')};
  }
`;

const StyledImage = styled(Img)`
  width: 200px;
`;

const Faq = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${theme.spacing('xl')};
  ${mq('2')} {
    margin-top: ${theme.spacing('xxxl')};
  }
`;

const FaqStyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: ${theme.spacing('m')};
  ${mq('2')} {
    margin-bottom: ${theme.spacing('l')};
  }
`;

const FaqLinkWrapper = styled.div`
  margin-top: ${theme.spacing('m')};
`;

const FaqButtonWrapper = styled.div``;

const AccordionWrapper = styled.div`
  margin-bottom: ${theme.spacing('m')};
  ${mq('2')} {
    margin-bottom: ${theme.spacing('m')};
  }
`;

const CtaSection = styled(Section)`
  margin-top: ${theme.spacing('xl')};
  ${mq('2')} {
    margin-top: ${theme.spacing('xxxl')};
  }
`;

const IndexPage = () => {
  const {
    datoCmsHomepage: copy,
    students,
    financial,
    organization,
  } = useStaticQuery(graphql`
    query getHome {
      datoCmsHomepage {
        title
        intro
        cta
        mapTitle
        nationalTitle
        nationalBody
        studentsHeading
        studentsBody
        financialHeading
        financialBody
        organizationHeading
        organizationBody
        faqTitle
        faq {
          title: question
          body: answer
          buttonLabel
          buttonLink
        }
        faqCtaLabel
        faqCtaLink
        sodkCta {
          title
          content
          buttonLabel
          buttonLink
          image {
            fluid(maxWidth: 200, maxHeight: 66) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        seoMetaTags {
          tags
        }
      }
      students: file(relativePath: { eq: "leerlingen.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      financial: file(relativePath: { eq: "financien.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      organization: file(relativePath: { eq: "organisatie.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const images = {
    students,
    financial,
    organization,
  };

  return (
    <Layout>
      <SEO tags={path(['seoMetaTags', 'tags'], copy)} />
      <HeroV1
        main={{
          content: (
            <>
              <Heading component="h1" size="xxxl" weight="semibold">
                {prop('title', copy)}
              </Heading>
              <Text size="l">{prop('intro', copy)}</Text>
              <Link to="/over">
                <Button
                  color="tertiary"
                  styling="naked"
                  type="button"
                  style={{ textAlign: 'left' }}
                >
                  <span>{prop('cta', copy)}</span>
                  <Icon size="lg" type="arrowCircleRight" />
                </Button>
              </Link>
            </>
          ),
          imageUrl: '/images/home.png',
        }}
        search={{
          content: (
            <>
              <Heading
                appearance="inherit"
                as="h2"
                component="h1"
                size="xxl"
                weight="semibold"
              >
                {prop('mapTitle', copy)}
              </Heading>
              <Search
                onItemSelect={(e, { suggestion }) => {
                  const prefix =
                    path(['partnership', 'sector'], suggestion) === 'PO'
                      ? 'primair-onderwijs'
                      : 'voortgezet-onderwijs';

                  const externalId = path(
                    ['partnership', 'externalId'],
                    suggestion
                  );
                  navigate(`/samenwerkingsverband/${prefix}/${externalId}/`);
                }}
              />
              <Link to="/kaart">
                <Button
                  color="secondary"
                  disabled={false}
                  small={false}
                  styling="naked"
                  type="button"
                >
                  <Icon size="lg" type="map" />
                  <span>Zoek via de kaart</span>
                </Button>
              </Link>
            </>
          ),
          imageUrl: '/images/po.svg',
        }}
      />
      <MainWrapper>
        <Section>
          <IntroductionHeading
            component="h1"
            size="xxxl"
            weight="semibold"
            appearance="secondary"
          >
            {prop('nationalTitle', copy)}
          </IntroductionHeading>
          <IntroductionText
            component="span"
            size="xl"
            weight="bold"
            appearance="secondary"
          >
            {parseTooltip(copy['nationalBody'])}
          </IntroductionText>
        </Section>
        {themes.map((key, index) => (
          <Section size={'2'} key={key}>
            <HighlightCard
              node={
                <Img fluid={path([key, 'childImageSharp', 'fluid'], images)} />
              }
              orientation={index % 2 === 0 ? 'ltr' : 'rtl'}
            >
              <Heading appearance="secondary" weight="semibold" size="xxxl">
                {parseTooltip(copy[`${key}Heading`])}
              </Heading>
              <Text size="l">{parseTooltip(copy[`${key}Body`])}</Text>
              <ButtonsWrapper>
                <Link
                  to={`/landelijk-inzicht/primair-onderwijs/${translateTheme(
                    key
                  )}`}
                >
                  <Button
                    color="primary"
                    disabled={false}
                    small={false}
                    styling="solid"
                    type="button"
                  >
                    <span>Primair onderwijs</span>
                    <Icon size="lg" type="arrowCircleRight" />
                  </Button>
                </Link>
                <Link
                  to={`/landelijk-inzicht/voortgezet-onderwijs/${translateTheme(
                    key
                  )}`}
                >
                  <Button
                    color="primary"
                    disabled={false}
                    small={false}
                    styling="solid"
                    type="button"
                  >
                    <span>Voortgezet onderwijs</span>
                    <Icon size="lg" type="arrowCircleRight" />
                  </Button>
                </Link>
              </ButtonsWrapper>
            </HighlightCard>
          </Section>
        ))}
        <Section style={{ backgroundColor: `${theme.color('card.bg')}` }}>
          <Faq>
            <FaqStyledHeading component="h1" size="xxl" appearance="secondary">
              {prop('faqTitle', copy)}
            </FaqStyledHeading>
            <AccordionWrapper>
              {path(['faq'], copy).map(
                ({ buttonLabel, buttonLink, ...props }, index) => (
                  <Accordion
                    {...props}
                    link={
                      <FaqLinkWrapper>
                        <a href={buttonLink} target="_blank" rel="noreferrer">
                          <Button
                            disabled={false}
                            small={false}
                            styling="naked"
                            type="button"
                          >
                            <span>{buttonLabel}</span>
                            <Icon size="lg" type="arrowCircleRight" />
                          </Button>
                        </a>
                      </FaqLinkWrapper>
                    }
                    key={`faq-${index}`}
                  />
                )
              )}
            </AccordionWrapper>
            <FaqButtonWrapper>
              <Link to={`/${prop('faqCtaLink', copy)}`}>
                <Button styling="naked" type="button">
                  <span>{prop('faqCtaLabel', copy)}</span>
                  <Icon size="lg" type="arrowCircleRight" />
                </Button>
              </Link>
            </FaqButtonWrapper>
          </Faq>
        </Section>
        <CtaSection>
          <CallToAction
            node={<StyledImage {...path(['sodkCta', 'image'], copy)} />}
          >
            <StyledHeading component="h1" size="xxl" appearance="secondary">
              {path(['sodkCta', 'title'], copy)}
            </StyledHeading>
            <StyledText size="m" appearance="base">
              {path(['sodkCta', 'content'], copy)}
            </StyledText>
            <ButtonWrapper>
              <a
                href={path(['sodkCta', 'buttonLink'], copy)}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  disabled={false}
                  small={false}
                  styling="solid"
                  type="button"
                >
                  <span>{path(['sodkCta', 'buttonLabel'], copy)}</span>
                  <Icon size="lg" type="arrowCircleRight" />
                </Button>
              </a>
            </ButtonWrapper>
          </CallToAction>
        </CtaSection>
      </MainWrapper>
    </Layout>
  );
};

IndexPage.propTypes = {};

export default IndexPage;
